import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
export default async ({ store, app }) => {
  if (process.server) {
    return;
  }
  const token = app.$cookies.get('auth');
  if (token) {
    await Vue.prototype.api.getMe(true)
      .then((user) => {
        if (user) store.dispatch('login', user);
      })
      .catch(async () => {
        await store.dispatch('logout');

        setTimeout(() => {
          window.location = '/';
        }, 30);
      });
  }
  store.dispatch('activeUserFetchedStatus');
};
