import redirector from '@/service/redirector';

export default (context) => {
  // eslint-disable-next-line
  const { query, app, route, redirect } = context;

  // eslint-disable-next-line no-underscore-dangle
  if (query?._jwt) {
    // eslint-disable-next-line no-underscore-dangle
    app.$cookies.set('auth', `Bearer ${query._jwt}`, { path: '/' });
    redirect(redirector.linkToCore(route.path));
  }
};
