/**
 * https://jr.synergy.ru/browse/ESK-3474
 */
export const addAdmitadScript = () => {
  const leadId = localStorage.getItem('lastLeadId');

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    ADMITAD = window.ADMITAD || {};
    ADMITAD.Invoice = ADMITAD.Invoice || {};
    if (!getSourceCookie(cookie_name)) {
    ADMITAD.Invoice.broker = 'na';
    } else if (getSourceCookie(cookie_name) != deduplication_cookie_value) {
    ADMITAD.Invoice.broker = getSourceCookie(cookie_name);
    } else {
    ADMITAD.Invoice.broker = 'adm';
    }
    ADMITAD.Invoice.category = '1'; // код целевого действия (определяется при интеграции)
    var orderedItem = []; // временный массив
    orderedItem.push({
      Product: {
          category: '1', // код тарифа (определяется при интеграции)
          price: '40000', // сумма заказа или 0 ( так как фикс ставка )
          priceCurrency: 'RUB', // код валюты ISO-4217 alfa-3 >(передавать при процентном вознаграждении)
      },
      orderQuantity: '1', // // количество товара
      additionalType: 'sale' // всегда sale
    });
    ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];
    ADMITAD.Invoice.referencesOrder.push({
    orderNumber: '${leadId}', // внутренний номер заказа (не более 100 символов)
    orderedItem: orderedItem
    });
    // Важно! Если данные по заказу Admitad подгружаются через AJAX, раскомментируйте следующую строку.
    ADMITAD.Tracking.processPositions();
    `;
  document.head.appendChild(script);
};

export const addDeduplicationScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    // имя cookie для хранения источника если используется другое имя, укажите его
    var cookie_name = 'deduplication_cookie';
    // число дней жизни cookie
    var days_to_store = 90;
    // ожидаемое значение deduplication_cookie для Admitad
    var deduplication_cookie_value = 'admitad';
    // имя GET-параметра для дедупликации если у вас используется другой параметр, укажите его имя
    var channel_name = 'utm_source';
    // var channel_name = ‘utm_source’;
    // функция для получения источника из GET параметра
    getSourceParamFromUri = function () {
      var pattern = channel_name + '=([^&]+)';
      var re = new RegExp(pattern);
      return (re.exec(document.location.search) || [])[1] || '';
    };
    // функция для получения источника из cookie с именем cookie_name
    getSourceCookie = function () {
      var matches = document.cookie.match(new RegExp(
        '(?:^|; )' + cookie_name.replace(/([\\.$?*|{}\\(\\)\\[\\]\\\\\\/\\+^])/g, '\\\\$1') + '=([^;]*)'
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    };
    // функция для записи источника в cookie с именем cookie_name
    setSourceCookie = function () {
      var param = getSourceParamFromUri();
      if (!param) { return; }
      var period = days_to_store * 60 * 60 * 24 * 1000; // в секундах
      var expiresDate = new Date((period) + +new Date);
      var cookieString = cookie_name + '=' + param + '; path=/; expires=' + expiresDate.toGMTString();
      document.cookie = cookieString;
      document.cookie = cookieString + '; domain=.' + location.host;
    };
    // запись cookie
    setSourceCookie();
    `;
  document.head.appendChild(script);
};

export default {
  addAdmitadScript,
  addDeduplicationScript,
};
