/* eslint-disable object-curly-newline */
import Vue from 'vue';

import { ApolloClient, InMemoryCache, ApolloLink, from, fromPromise } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';

import ServiceApi from '../service/api';
import MaterialsApi from '../service/materials';
import QuestionnaireApi from '../service/questionnaire';

Object.assign(ServiceApi.prototype, MaterialsApi, QuestionnaireApi);

const endPoint = `${process.env.graphql}graphql`;
const kidsEndPoint = `${process.env.graphqlKids}graphql`;

export default ({ app }, inject) => {
  const errorLink = onError(({ networkError, operation, forward }) => {
    if (process.client) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (networkError?.statusCode === 401 && operation.operationName !== 'signOut' && refreshToken) {
        return fromPromise(
          app.$api.refreshToken().catch(() => {
            localStorage.removeItem('refreshToken');
            return null;
          }),
        )
          .flatMap((accessToken) => {
            if (!accessToken) return false;

            const oldHeaders = operation.getContext().headers;

            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken}`,
              },
            });
            return forward(operation);
          });
      }
    }

    return false;
  });

  const token = app.$cookies.get('auth');
  const headers = token ? { authorization: token } : {};

  const extendsLink = new ApolloLink((operation, forward) => {
    const tokenApollo = app.$cookies.get('auth');
    let headersApollo = tokenApollo ? { authorization: tokenApollo } : {};
    if (process.client) {
      headersApollo = { ...headersApollo };
    }

    operation.setContext({ headers: headersApollo });
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const responseHeaders = context.response.headers;

      if (responseHeaders) {
        const accessToken = responseHeaders.get('AccessToken');
        if (accessToken) {
          const cookieDomain = window?.location?.hostname || process.env.cookie_domain;
          app.$cookies.set('auth', accessToken, { domain: cookieDomain, path: '/' });
        }

        const refreshToken = responseHeaders.get('refreshToken');
        if (refreshToken) {
          localStorage.setItem('refreshToken', refreshToken);
        }
      }

      return response;
    });
  });

  const uploadLink = extendsLink.concat(createUploadLink({
    uri: endPoint,
    fetch,
    headers,
  }));
  const kidsUploadLink = extendsLink.concat(createUploadLink({
    uri: kidsEndPoint,
    fetch,
    headers,
  }));
  const httpLink = ApolloLink.split(
    (operation) => operation.getContext().client === 'kidsUploadLink',
    kidsUploadLink,
    uploadLink,
  );

  const apolloClient = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
  const api = new ServiceApi(apolloClient, app);
  inject('api', api);
  Vue.prototype.api = api;
};
