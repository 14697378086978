let stopped = true;

function animateSnowflake(snowfl) {
  const snowflake = snowfl;
  const speed = Math.random() * 2 + 1;
  const direction = Math.random() > 0.5 ? 1 : -1;
  const amplitude = Math.random() + 1;

  function moveSnowflake() {
    if (stopped) return;

    const translateMatch = snowflake.style.transform.match(/\d+/g);
    if (!translateMatch) return;

    const currentTop = parseFloat(translateMatch[1]);
    const topPosition = Math.round((currentTop + speed) % document.documentElement.clientHeight);

    const currentLeft = parseFloat(translateMatch[0]);
    const newLeft = currentLeft + direction * amplitude * Math.sin(currentTop / 50);
    const leftPosition = Math.round((newLeft + window.innerWidth) % window.innerWidth);

    snowflake.style.transform = `translate(${leftPosition}px, ${topPosition}px)`;

    requestAnimationFrame(moveSnowflake);
  }

  moveSnowflake();
}

function createSnowflake() {
  const snowflake = document.createElement('div');
  snowflake.className = 'snowflake';
  snowflake.innerHTML = '.';

  const startLeft = Math.round(Math.random() * window.innerWidth);
  const startTop = Math.round(Math.random() * window.innerHeight);

  snowflake.style.transform = `translate(${startLeft}px, ${startTop}px)`;

  document.body.appendChild(snowflake);

  animateSnowflake(snowflake);
}

export const addSnowFlakes = () => {
  const snowflakeCount = 30;
  stopped = false;

  for (let i = 0; i < snowflakeCount; i += 1) {
    createSnowflake();
  }
};

export const removeSnowFlakes = () => {
  document.querySelectorAll('.snowflake').forEach((elem) => {
    elem.remove();
  });
  stopped = true;
};

export default { addSnowFlakes };
