/**
 * чтобы работал this.$route, нужно привязать функцию к контексту vue (bind, call, apply)
 * @param elem - html element
 * @param pagePath - страница на котором находится elem, можно пропустить если текущая страница
 * @param SCROLL_TOP_OFFSET - отступ скролла, чтобы хедер не перекрывал элемент
 */
export default function scrollTo(elem, pagePath, SCROLL_TOP_OFFSET = 120) {
  if (!elem) {
    // eslint-disable-next-line no-console
    console.error('Элемент для скролла не задан');
    return;
  }

  if (pagePath && this?.$route?.path !== pagePath) {
    this.$router.push({ path: pagePath });
  }

  setTimeout(() => {
    const element = document.querySelector(elem);

    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y - SCROLL_TOP_OFFSET,
        behavior: 'smooth',
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(`Элемент "${elem}" не найден на странице`);
    }
  }, 100);
}
