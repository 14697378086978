export default (route) => {
  const queryKeys = Object.keys(route?.query || {});
  if (queryKeys.length) {
    const haveUtmData = queryKeys.some((queryKey) => /^utm_/.test(queryKey));
    if (haveUtmData) {
      return route.query;
    }
  }

  return {};
};
