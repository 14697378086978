import { gql } from '@apollo/client';

export const GET_PROF_TEST_QUESTIONS = gql`
  query getProfTestQuestions {
    getProfTestQuestions {
      firstBlock {
        attributeGroup
        id
        position
        text
      }
      secondBlock {
        attributeGroup
        id
        position
        text
      }
      startPage {
        attributeGroup
        id
        position
        text
      }
    }
  }
`;

export const SAVE_PROF_TEST_ANSWER = gql`
  mutation saveProfTestAnswer(
    $leadId: ID!
    $onboardingAttributeId: ID!
    $score: Int
    $value: String
  ) {
    saveProfTestAnswer(
      leadId: $leadId
      onboardingAttributeId: $onboardingAttributeId
      score: $score
      value: $value
    ) {
      success
      errors {
        message
        objectField
        valid
      }
    }
  }
`;

export const FINALIZE_PROF_TEST_ONBOARDING_REPORT = gql`
  mutation finalizeProfTestOnboardingReport($leadId: ID!) {
    finalizeProfTestOnboardingReport(leadId: $leadId) {
      errors {
        message
      }
      linkToResult
      success
    }
  }
`;

export const SUMMARY_ONBOARDING_REPORT_RESULT = gql`
  query summaryOnboardingReportResult($guid: String!) {
    summaryOnboardingReportResult(guid: $guid) {
      createdAt
      extraProfClass
      firstBlockResult
      guid
      mainProfClass
      personalityType
    }
  }
`;

export const SAVE_QUESTIONNARIE_ANSWERS = gql`
  mutation saveQuestionnarieAnswers(
    $answers: [QuestionAnswerInput!]!
    $leadId: ID!
    $questionnarieName: String!
  ) {
    saveQuestionnarieAnswers(
      answers: $answers
      leadId: $leadId
      questionnarieName: $questionnarieName
    ) {
      errors {
        objectField
        message
        valid
      }
      success
      lead {
        id
      }
    }
  }
`;
