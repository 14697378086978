import Vue from 'vue';

Vue.filter('prettyPhone', (str) => (
  str.replace(/\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5 ')
));

Vue.filter('prettyCurrency', (value, withCurrency = true) => {
  if (typeof value !== 'number') {
    return value;
  }

  const options = {
    maximumFractionDigits: 0,
  };

  if (withCurrency) {
    options.style = 'currency';
    options.currency = 'RUB';
  }

  const formatter = new Intl.NumberFormat('ru-RU', options);

  return formatter.format(value);
});
