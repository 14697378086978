import createPersistedState from 'vuex-persistedstate';

export default ({ store }) => {
  createPersistedState({
    key: 'school-vuex',
    paths: [
      'user',
    ],
  })(store);
};
