/* eslint-disable no-param-reassign */
export default {
  state() {
    return {
      auth: false,
      lead1: false,
      lead2: false,
      lead3: false,
      lead4: false,
      lead5: false,
      lead6: false,
      lead7: false,
      lead8: false,
      lead9: false,
      lead10: false,
      lead11: false,
      lead12: false,
      lead13: false,
      lead14: false,
      lead15: false,
      toggleLead2leadTitle: null,
      softDownload: false,
      blackFriday: false,
      newYear: false,
      january: false,
      openDoors: false,
      nabor24: false,
      anapa24: false,
      videoModalSrc: null,
    };
  },
  mutations: {
    setAuth(state, value) {
      state.auth = value;
    },
    setLead1(state, value) {
      state.lead1 = value;
    },
    setLead2(state, value) {
      state.lead2 = value;
    },
    setLead3(state, value) {
      state.lead3 = value;
    },
    setLead4(state, value) {
      state.lead4 = value;
    },
    setLead5(state, value) {
      state.lead5 = value;
    },
    setLead6(state, value) {
      state.lead6 = value;
    },
    setLead7(state, value) {
      state.lead7 = value;
    },
    setLead8(state, value) {
      state.lead8 = value;
    },
    setLead9(state, value) {
      state.lead9 = value;
    },
    setLead10(state, value) {
      state.lead10 = value;
    },
    setLead11(state, value) {
      state.lead11 = value;
    },
    setLead12(state, value) {
      state.lead12 = value;
    },
    setLead13(state, value) {
      state.lead13 = value;
    },
    setLead14(state, value) {
      state.lead14 = value;
    },
    setLead15(state, value) {
      state.lead15 = value;
    },
    setLeadTitle(state, title) {
      state.leadTitle = title;
    },
    setSoftDownload(state, value) {
      state.softDownload = value;
    },
    setBlackFriday(state, value) {
      state.blackFriday = value;
    },
    setNewYear(state, value) {
      state.newYear = value;
    },
    setJanuary(state, value) {
      state.january = value;
    },
    setOpenDoors(state, value) {
      state.openDoors = value;
    },
    setNabor24(state, value) {
      state.nabor24 = value;
    },
    setAnapa24(state, value) {
      state.anapa24 = value;
    },
    setVideoSrc(state, src) {
      state.videoModalSrc = src;
    },
  },
  getters: {
    authModal(state) {
      return state.auth;
    },

    lead1Modal(state) {
      return state.lead1;
    },
    lead2Modal(state) {
      return state.lead2;
    },
    lead3Modal(state) {
      return state.lead3;
    },
    lead4Modal(state) {
      return state.lead4;
    },
    lead5Modal(state) {
      return state.lead5;
    },
    lead6Modal(state) {
      return state.lead6;
    },
    lead7Modal(state) {
      return state.lead7;
    },
    lead8Modal(state) {
      return state.lead8;
    },
    lead9Modal(state) {
      return state.lead9;
    },
    lead10Modal(state) {
      return state.lead10;
    },
    lead11Modal(state) {
      return state.lead11;
    },
    lead12Modal(state) {
      return state.lead12;
    },
    lead13Modal(state) {
      return state.lead13;
    },
    lead14Modal(state) {
      return state.lead14;
    },
    lead15Modal(state) {
      return state.lead15;
    },
    leadModal(state, getters) {
      return getters.lead1Modal
        || getters.lead2Modal
        || getters.lead3Modal
        || getters.lead4Modal
        || getters.lead5Modal
        || getters.lead6Modal
        || getters.lead7Modal
        || getters.lead8Modal
        || getters.lead9Modal
        || getters.lead10Modal
        || getters.lead11Modal
        || getters.lead12Modal
        || getters.lead13Modal
        || getters.lead14Modal
        || getters.lead15Modal;
    },
    leadTitle(state) {
      return state.leadTitle;
    },

    softDownloadModal(state) {
      return state.softDownload;
    },
    blackFridayModal(state) {
      return state.blackFriday;
    },
    newYearModal(state) {
      return state.newYear;
    },
    januaryModal(state) {
      return state.january;
    },
    openDoorsModal(state) {
      return state.openDoors;
    },
    nabor24Modal(state) {
      return state.nabor24;
    },
    anapa24Modal(state) {
      return state.anapa24;
    },

    videoModal(state) {
      return Boolean(state.videoModalSrc);
    },
    videoModalSrc(state) {
      return state.videoModalSrc;
    },
  },
  actions: {
    closeAll({ dispatch }) {
      dispatch('closeAuth');
      dispatch('closeLead');
      dispatch('closeVideo');
      dispatch('closeSoftDownload');
      dispatch('closeBlackFriday');
      dispatch('closeNewYear');
      dispatch('closeJanuary');
      dispatch('closeOpenDoors');
      dispatch('closeNabor24');
      dispatch('closeAnapa24');
    },

    toggleAuth({ commit, state }) {
      commit('setAuth', !state.auth);
    },
    closeAuth({ commit }) {
      commit('setAuth', false);
    },

    toggleLead1({ commit, state }) {
      commit('setLead1', !state.lead1);
    },
    toggleLead2({ commit, state }, leadTitle = null) {
      commit('setLeadTitle', typeof leadTitle === 'string' ? leadTitle : null);
      commit('setLead2', !state.lead2);
    },
    toggleLead3({ commit, state }) {
      commit('setLead3', !state.lead3);
    },
    toggleLead4({ commit, state }) {
      commit('setLead4', !state.lead4);
    },
    toggleLead5({ commit, state }) {
      commit('setLead5', !state.lead5);
    },
    toggleLead6({ commit, state }) {
      commit('setLead6', !state.lead6);
    },
    toggleLead7({ commit, state }) {
      commit('setLead7', !state.lead7);
    },
    toggleLead8({ commit, state }) {
      commit('setLead8', !state.lead8);
    },
    toggleLead9({ commit, state }) {
      commit('setLead9', !state.lead9);
    },
    toggleLead10({ commit, state }) {
      commit('setLead10', !state.lead10);
    },
    toggleLead11({ commit, state }) {
      commit('setLead11', !state.lead11);
    },
    toggleLead12({ commit, state }) {
      commit('setLead12', !state.lead12);
    },
    toggleLead13({ commit, state }) {
      commit('setLead13', !state.lead13);
    },
    toggleLead14({ commit, state }) {
      commit('setLead14', !state.lead14);
    },
    toggleLead15({ commit, state }) {
      commit('setLead15', !state.lead14);
    },
    closeLead({ commit }) {
      commit('setLeadTitle', null);
      commit('setLead1', false);
      commit('setLead2', false);
      commit('setLead3', false);
      commit('setLead4', false);
      commit('setLead5', false);
      commit('setLead6', false);
      commit('setLead7', false);
      commit('setLead8', false);
      commit('setLead9', false);
      commit('setLead10', false);
      commit('setLead11', false);
      commit('setLead12', false);
      commit('setLead13', false);
      commit('setLead14', false);
      commit('setLead15', false);
    },

    openSoftDownload({ commit }) {
      commit('setSoftDownload', true);
    },
    closeSoftDownload({ commit }) {
      commit('setSoftDownload', false);
    },

    openBlackFriday({ commit }) {
      commit('setBlackFriday', true);
    },
    closeBlackFriday({ commit }) {
      commit('setBlackFriday', false);
    },

    openNewYear({ commit }) {
      commit('setNewYear', true);
    },
    closeNewYear({ commit }) {
      commit('setNewYear', false);
    },

    openJanuary({ commit }) {
      commit('setJanuary', true);
    },
    closeJanuary({ commit }) {
      commit('setJanuary', false);
    },

    openOpenDoors({ commit }) {
      commit('setOpenDoors', true);
    },
    closeOpenDoors({ commit }) {
      commit('setOpenDoors', false);
    },

    openNabor24({ commit }) {
      commit('setNabor24', true);
    },
    closeNabor24({ commit }) {
      commit('setNabor24', false);
    },

    openAnapa24({ commit }) {
      commit('setAnapa24', true);
    },
    closeAnapa24({ commit }) {
      commit('setAnapa24', false);
    },

    openVideo({ commit }, src) {
      commit('setVideoSrc', src);
    },
    closeVideo({ commit }) {
      commit('setVideoSrc', null);
    },
  },
};
