export default (timerEndDate) => {
  const now = new Date();
  const remainingMilliSeconds = +timerEndDate - +now;

  const days = Math.max(Math.floor(remainingMilliSeconds / (1000 * 60 * 60 * 24)), 0);
  const hours = Math.max(Math.floor((remainingMilliSeconds / (1000 * 60 * 60)) % 24), 0);
  const minutes = Math.max(Math.floor((remainingMilliSeconds / (1000 * 60)) % 60), 0);
  const seconds = Math.max(Math.floor((remainingMilliSeconds / 1000) % 60), 0);

  return {
    days: String(days).padStart(2, '0'),
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
  };
};
