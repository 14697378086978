export default (ctx, inject) => {
  function init() {
    const head = document.getElementsByTagName('head');

    if (head && head[0]) {
      const script = document.createElement('script');
      script.src = './fb-pixel.js';

      const noScript = document.createElement('noscript');
      // eslint-disable-next-line max-len,vue/max-len
      noScript.innerHTML = '<img height="1" width="1" src="https://www.facebook.com/tr?id=648536186401188&ev=PageView&noscript=1"/>';

      head[0].append(script);
      head[0].append(noScript);
    }
  }

  ctx.$fbPixel = { init };
  inject('fbPixel', { init });
};
