const SESSION_STORAGE_KEY = 'utm';

export const saveUtm = (route) => {
  if (!localStorage) return;

  const queryKeys = Object.keys(route?.query || {});

  if (queryKeys.length) {
    const haveUtmData = queryKeys.some((queryKey) => /^utm_/.test(queryKey));

    if (haveUtmData) {
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(route.query));
    }
  }
};

/**
 * TODO: ESK-4502
 * Почему-то на проде этот вариант плохо сработал
 * возможно стоит при наличии брать из url
 * а потом только смотреть в localStorage
 */
export const getUtm = () => {
  if (!localStorage) return {};

  const utm = localStorage.getItem(SESSION_STORAGE_KEY);
  if (utm) {
    return JSON.parse(utm);
  }
  return {};
};

export const removeUtm = () => {
  if (!localStorage) return;
  localStorage.removeItem(SESSION_STORAGE_KEY);
};

export default { saveUtm, getUtm, removeUtm };
