import * as scheme from './scheme/questionnaire.gql';

export default {
  getProfTestQuestions() {
    return this.query({
      query: scheme.GET_PROF_TEST_QUESTIONS,
    })
      .then((response) => response.data?.getProfTestQuestions || {});
  },

  saveProfTestAnswer(variables) {
    return this.mutate({
      mutation: scheme.SAVE_PROF_TEST_ANSWER,
      variables,
    });
  },

  profTestReportLink(leadId) {
    return this.mutate({
      mutation: scheme.FINALIZE_PROF_TEST_ONBOARDING_REPORT,
      variables: { leadId },
    })
      .then(({ data }) => data?.finalizeProfTestOnboardingReport || {});
  },

  summaryOnboardingReportResult(guid) {
    return this.query({
      query: scheme.SUMMARY_ONBOARDING_REPORT_RESULT,
      variables: { guid },
    })
      .then((response) => response.data?.summaryOnboardingReportResult || null);
  },

  saveQuestionnarieAnswer(answers, questionnarieName) {
    const leadId = localStorage.getItem('lastLeadId');

    return this.mutate({
      mutation: scheme.SAVE_QUESTIONNARIE_ANSWERS,
      variables: {
        answers,
        leadId,
        questionnarieName,
      },
    }).then((response) => response?.data?.saveQuestionnarieAnswer);
  },
};
