const ONLINE_SCHOOL_DOMAINS = [
  // 'localhost:3001',
  'online-school.syndev.ru',
  'online-school.synergy.ru',
];

export default ({ req, route, redirect }) => {
  const isOnlineSchoolDomain = ONLINE_SCHOOL_DOMAINS.includes(req?.headers?.host);
  const onlineSchoolPage = ['/online-school-ceny-poddomen', '/thanks'].includes(route?.path);

  if (isOnlineSchoolDomain && !onlineSchoolPage) {
    redirect(301, '/online-school-ceny-poddomen');
  }

  if (!isOnlineSchoolDomain && route?.path === '/online-school-ceny-poddomen') {
    redirect(301, '/');
  }
};
