/* eslint-disable no-param-reassign */

export default {
  state() {
    return {
      freeSchoolLead: false,
      freeSchoolDocumentsLead: false,
      freeExternalLead: false,
      freeEgeOgeLead: false,
      freeEgeOgeProfessionalLead: false,
      freeTutorsLead: false,
      freeTutorsJuniorLead: false,
      freeChildLead: false,
      freeChildProfessionalLead: false,
      freeChildBlogLead: false,
      freeChildLanguageLead: false,
      freeChildPersonalLead: false,
      freeITLead: false,
      freeITFutureLead: false,
      freeITProgrammingLead: false,
      freeFreeLead: false,
      freeFreeConsultationLead: false,
    };
  },

  mutations: {
    setFreeSchoolLead(state, value) {
      state.freeSchoolLead = value;
    },
    setFreeSchoolDocumentsLead(state, value) {
      state.freeSchoolDocumentsLead = value;
    },
    setFreeExternalLead(state, value) {
      state.freeExternalLead = value;
    },
    setFreeEgeOgeLead(state, value) {
      state.freeEgeOgeLead = value;
    },
    setFreeEgeOgeProfessionalLead(state, value) {
      state.freeEgeOgeProfessionalLead = value;
    },
    setFreeTutorsLead(state, value) {
      state.freeTutorsLead = value;
    },
    setFreeTutorsJuniorLead(state, value) {
      state.freeTutorsJuniorLead = value;
    },
    setFreeChildLead(state, value) {
      state.freeChildLead = value;
    },
    setFreeChildProfessionalLead(state, value) {
      state.freeChildProfessionalLead = value;
    },
    setFreeChildBlogLead(state, value) {
      state.freeChildBlogLead = value;
    },
    setFreeChildLanguageLead(state, value) {
      state.freeChildLanguageLead = value;
    },
    setFreeChildPersonalLead(state, value) {
      state.freeChildPersonalLead = value;
    },
    setFreeITLead(state, value) {
      state.freeITLead = value;
    },
    setFreeITFutureLead(state, value) {
      state.freeITFutureLead = value;
    },
    setFreeITProgrammingLead(state, value) {
      state.freeITProgrammingLead = value;
    },
    setFreeFreeLead(state, value) {
      state.freeFreeLead = value;
    },
    setFreeFreeConsultationLead(state, value) {
      state.freeFreeConsultationLead = value;
    },
  },

  getters: {
    showHeaderModal(state) {
      return state.freeSchoolLead
        || state.freeSchoolDocumentsLead
        || state.freeExternalLead
        || state.freeEgeOgeLead
        || state.freeEgeOgeProfessionalLead
        || state.freeTutorsLead
        || state.freeTutorsJuniorLead
        || state.freeChildLead
        || state.freeChildProfessionalLead
        || state.freeChildBlogLead
        || state.freeChildLanguageLead
        || state.freeChildPersonalLead
        || state.freeITLead
        || state.freeITFutureLead
        || state.freeITProgrammingLead
        || state.freeFreeLead
        || state.freeFreeConsultationLead;
    },
    freeSchoolLeadModal(state) {
      return state.freeSchoolLead;
    },
    freeSchoolDocumentsLeadModal(state) {
      return state.freeSchoolDocumentsLead;
    },
    freeExternalLeadModal(state) {
      return state.freeExternalLead;
    },
    freeEgeOgeLeadModal(state) {
      return state.freeEgeOgeLead;
    },
    freeEgeOgeProfessionalLeadModal(state) {
      return state.freeEgeOgeProfessionalLead;
    },
    freeTutorsLeadModal(state) {
      return state.freeTutorsLead;
    },
    freeTutorsJuniorLeadModal(state) {
      return state.freeTutorsJuniorLead;
    },
    freeChildLeadModal(state) {
      return state.freeChildLead;
    },
    freeChildProfessionalLeadModal(state) {
      return state.freeChildProfessionalLead;
    },
    freeChildBlogLeadModal(state) {
      return state.freeChildBlogLead;
    },
    freeChildLanguageLeadModal(state) {
      return state.freeChildLanguageLead;
    },
    freeChildPersonalLeadModal(state) {
      return state.freeChildPersonalLead;
    },
    freeITLeadModal(state) {
      return state.freeITLead;
    },
    freeITFutureLeadModal(state) {
      return state.freeITFutureLead;
    },
    freeITProgrammingLeadModal(state) {
      return state.freeITProgrammingLead;
    },
    freeFreeLeadModal(state) {
      return state.freeFreeLead;
    },
    freeFreeConsultationLeadModal(state) {
      return state.freeFreeConsultationLead;
    },
  },

  actions: {
    closeHeaderModal({ commit }) {
      commit('setFreeSchoolLead', false);
      commit('setFreeSchoolDocumentsLead', false);
      commit('setFreeExternalLead', false);
      commit('setFreeEgeOgeLead', false);
      commit('setFreeEgeOgeProfessionalLead', false);
      commit('setFreeTutorsLead', false);
      commit('setFreeTutorsJuniorLead', false);
      commit('setFreeChildLead', false);
      commit('setFreeChildProfessionalLead', false);
      commit('setFreeChildBlogLead', false);
      commit('setFreeChildLanguageLead', false);
      commit('setFreeChildPersonalLead', false);
      commit('setFreeITLead', false);
      commit('setFreeITFutureLead', false);
      commit('setFreeITProgrammingLead', false);
      commit('setFreeFreeLead', false);
      commit('setFreeFreeConsultationLead', false);
    },
    toggleFreeSchoolLead({ commit, state }) {
      commit('setFreeSchoolLead', !state.freeSchoolLead);
    },
    toggleFreeSchoolDocumentsLead({ commit, state }) {
      commit('setFreeSchoolDocumentsLead', !state.freeSchoolDocumentsLead);
    },
    toggleFreeExternalLead({ commit, state }) {
      commit('setFreeExternalLead', !state.freeExternalLead);
    },
    toggleFreeEgeOgeLead({ commit, state }) {
      commit('setFreeEgeOgeLead', !state.freeEgeOgeLead);
    },
    toggleFreeEgeOgeProfessionalLead({ commit, state }) {
      commit('setFreeEgeOgeProfessionalLead', !state.freeEgeOgeProfessionalLead);
    },
    toggleFreeTutorsLead({ commit, state }) {
      commit('setFreeTutorsLead', !state.freeTutorsLead);
    },
    toggleFreeTutorsJuniorLead({ commit, state }) {
      commit('setFreeTutorsJuniorLead', !state.freeTutorsJuniorLead);
    },
    toggleFreeChildLead({ commit, state }) {
      commit('setFreeChildLead', !state.freeChildLead);
    },
    toggleFreeChildProfessionalLead({ commit, state }) {
      commit('setFreeChildProfessionalLead', !state.freeChildProfessionalLead);
    },
    toggleFreeChildBlogLead({ commit, state }) {
      commit('setFreeChildBlogLead', !state.freeChildBlogLead);
    },
    toggleFreeChildLanguageLead({ commit, state }) {
      commit('setFreeChildLanguageLead', !state.freeChildLanguageLead);
    },
    toggleFreeChildPersonalLead({ commit, state }) {
      commit('setFreeChildPersonalLead', !state.freeChildPersonalLead);
    },
    toggleFreeITLead({ commit, state }) {
      commit('setFreeITLead', !state.freeITLead);
    },
    toggleFreeITFutureLead({ commit, state }) {
      commit('setFreeITFutureLead', !state.freeITFutureLead);
    },
    toggleFreeITProgrammingLead({ commit, state }) {
      commit('setFreeITProgrammingLead', !state.freeITProgrammingLead);
    },
    toggleFreeFreeLead({ commit, state }) {
      commit('setFreeFreeLead', !state.freeFreeLead);
    },
    toggleFreeFreeConsultationLead({ commit, state }) {
      commit('setFreeFreeConsultationLead', !state.freeFreeConsultationLead);
    },
  },
};
