const COM_DOMAINS = [
  // 'localhost:3001',
  'com.school.syndev.ru',
  'school.tl-synergy.com',
];

export default ({ req, route, redirect }) => {
  const isComDomain = COM_DOMAINS.includes(req?.headers?.host);
  const schoolComPage = route?.path === '/online-school-com';

  if (isComDomain && !schoolComPage) {
    redirect(301, '/online-school-com');
  }

  if (!isComDomain && schoolComPage) {
    redirect(301, '/');
  }
};
