/**
 * в зависимости от страницы разные анкеты
 */
const examPaths = [
  'ege',
  'marathon-ege-2024-drb',
  'itogovoe-sochinenie',
  'intensiv-ege',
  'oge',
  'itogovoe-sobesedovanie-2024',
  'intensiv-oge',
  'free/ege/kurs-ege-2024',
  'marathon-ege-matematika-2024',
  'marathon-ege-russkij-2024',
  'marathon-ege-2024',
];

const proforientaciaPaths = [
  'proforientacia',
];

export const getQuestionnairePath = (route) => {
  if (!route) return 'questionnaire';
  const slash = route.path?.slice(-1) === '/' ? '' : '/';

  const isExamPage = examPaths.some((path) => route.path.includes(path));
  if (isExamPage) return `${slash}questionnaire/OgeEge`;

  const isProforientaciaPage = proforientaciaPaths.some((path) => route.path.includes(path));
  if (isProforientaciaPage) return `${slash}proforientacia/test`;

  return `${slash}questionnaire`;
};

export default getQuestionnairePath;
