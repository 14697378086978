import { SUBJECTS } from '@/data/constants';

export default ({ route, redirect }) => {
  const allowedEgeSubpages = [
    SUBJECTS.matematika,
    SUBJECTS.matematikaProfil,
    SUBJECTS.russkijYazyk,
    SUBJECTS.anglijskijYazyk,
    SUBJECTS.informatika,
    SUBJECTS.obshchestvoznanie,
    SUBJECTS.istoriya,
    SUBJECTS.fizika,
    SUBJECTS.himiya,
    SUBJECTS.biologiya,
    SUBJECTS.literatura,
  ];
  const allowedOgeSubpages = allowedEgeSubpages.filter((item) => item !== SUBJECTS.matematikaProfil);

  if (route.name === 'ege-subpage' && !allowedEgeSubpages.includes(route?.params?.subpage)) {
    redirect('/ege');
  }

  if (route.name === 'oge-subpage' && !allowedOgeSubpages.includes(route?.params?.subpage)) {
    redirect('/oge');
  }
};
